<template>
  <div>
    <div class="banner">
      <main-top title="DSR智能计算器" desc="先查询对应店铺名称，输入目标值自动计算还需多少笔5分订单，店铺单量目标一目了然"></main-top>

      <bordure-row title="计算器" style="margin-bottom:30px;">
        <div class="calculator">
          <div  class="calculator-wrapper">
            <div class="calculatorLeft">宝贝与描述相符:</div>
            <el-form ref="form" :inline="true" class="formClass">
              <el-form-item style="margin-left: 20px">
                <el-input style="width: 120px" v-model="form[0].scoreCount" type="number" @input="testNumbFn">
                </el-input>
              </el-form-item>
              <div style="width: 62px; text-align: center">分，已</div>
              <el-form-item>
                <el-input style="width: 120px" v-model="form[0].count" @input="testNumbFn" type="number"></el-input>
              </el-form-item>
              <div style="width: 160px; text-align: center">
                人评分，输入目标得分:
              </div>
              <el-form-item>
                <el-input style="width: 120px" v-model.number="form[0].targetCount" @input="testNumbFn" type="number">
                </el-input>
              </el-form-item>
              <div style="margin: 0 22px 0 10px">分</div>
              <div style="font-size: 12px; color: #111111">
                还需要：
                <span style="color: red">{{ data[0].targetOrderCount }}单</span>
                ，5分评分到达目标分数
              </div>
            </el-form>
          </div>
          
          <div class="calculator-wrapper">
            <div class="calculatorLeft">宝贝与服务态度:</div>
            <el-form ref="form" :inline="true" class="formClass">
              <el-form-item style="margin-left: 20px">
                <el-input style="width: 120px" v-model="form[1].scoreCount" type="number" @input="testNumbFn">
                </el-input>
              </el-form-item>
              <div style="width: 62px; text-align: center">分，已</div>
              <el-form-item>
                <el-input style="width: 120px" v-model="form[1].count" @input="testNumbFn" type="number"></el-input>
              </el-form-item>
              <div style="width: 160px; text-align: center">
                人评分，输入目标得分:
              </div>
              <el-form-item>
                <el-input style="width: 120px" v-model.number="form[1].targetCount" @input="testNumbFn" type="number">
                </el-input>
              </el-form-item>
              <div style="margin: 0 22px 0 10px">分</div>
              <div style="font-size: 12px; color: #111111">
                还需要：
                <span style="color: red">{{ data[1].targetOrderCount }}单</span>
                ，5分评分到达目标分数
              </div>
            </el-form>
          </div>


          <div class="calculator-wrapper">
            <div class="calculatorLeft">物流服务的质量:</div>
            <el-form ref="form" :inline="true" class="formClass">
              <el-form-item style="margin-left: 20px">
                <el-input style="width: 120px" v-model="form[2].scoreCount" type="number" @input="testNumbFn">
                </el-input>
              </el-form-item>
              <div style="width: 62px; text-align: center">分，已</div>
              <el-form-item>
                <el-input style="width: 120px" v-model="form[2].count" @input="testNumbFn" type="number"></el-input>
              </el-form-item>
              <div style="width: 160px; text-align: center">
                人评分，输入目标得分:
              </div>
              <el-form-item>
                <el-input style="width: 120px" v-model.number="form[2].targetCount" @input="testNumbFn" type="number">
                </el-input>
              </el-form-item>
              <div style="margin: 0 22px 0 10px">分</div>
              <div style="font-size: 12px; color: #111111">
                还需要：
                <span style="color: red">{{ data[2].targetOrderCount }}单</span>
                ，5分评分到达目标分数
              </div>
            </el-form>
          </div>
        </div>

        <div class="querybtn">
          <el-button type="primary" @click="queryFn" size="small" style="width:140px;">查询</el-button>
        </div>
      </bordure-row>



      <!-- <div
        style="
          width: 100px;
          height: 36px;
          color: #fff;
          background: #5d7cff;
          border-radius: 4px;
          font-size: 14px;
          color: #ffffff;
          letter-spacing: 0;
          text-align: center;
          line-height: 36px;
          margin: 16px auto 0;
        "
      >
        查询
      </div> -->

      <Introduce :introduce="introduce"></Introduce>

    </div>

  </div>
</template>
<script>
  import {
    notLogin
  } from "@/publicApi";
  import {
    dsrIntelligentComputing
  } from "../../request/api"; //这里是引入请求
  import Introduce from "../public/Introduce";
  export default {
    data() {
      return {
        CanYouClick: true,
        form: [{
            id: 1,
            count: "0", //用户单量
            scoreCount: "0", //用户评分
            targetCount: "0", //目标评分
          },
          {
            id: 2,
            count: "0", //用户单量
            scoreCount: "0", //用户评分
            targetCount: "0", //目标评分
          },
          {
            id: 3,
            count: "0", //用户单量
            scoreCount: "0", //用户评分
            targetCount: "0", //目标评分
          },
        ],
        data: [{
            count: "30000",
            fivePenceOrderCount: "30000",
            id: 3,
            scoreCount: "4",
            targetCount: "4.5(模板)",
            targetOrderCount: "15000(模板)",
            totalCount: "5",
          },
          {
            count: "30000",
            fivePenceOrderCount: "30000",
            id: 3,
            scoreCount: "4",
            targetCount: "4.7(模板)",
            targetOrderCount: "1000(模板)",
            totalCount: "5",
          },
          {
            count: "30000",
            fivePenceOrderCount: "30000",
            id: 3,
            scoreCount: "4",
            targetCount: "4.8(模板)",
            targetOrderCount: "500(模板)",
            totalCount: "5",
          },
        ],
        introduce: [{
            title: "淘宝动态评分查询",
            content: [
              "1. DSR查询：结果精确到小数点后5位（例如：4.82077），数值比其它工具更精确",
              "2. 5分单数计算：查询对应店铺，输入您的目标分数，可以计算达到目标值还需要多少笔5分订单（设定目标分数要大于现有分数）",
              "3. DSR计算方式：分数×比率(该分值评价人数占总评价人数的比例）：5分×0.837+4分×0.113+3分×0.0278+2分×0.0037+1分×0.0185=4.7463",
              "4. 行业评分差值计算规则：(店铺得分-同行业平均分)/(同行业店铺最高得分-同行业平均分)，指自己店铺的dsr跟同类目店铺dsr平均值的相差是多少",
            ],
          },
          {
            title: '淘宝动态评分',
            content: [
              "1. DSR是卖家服务评级, 由宝贝与描述相符、卖家的服务态度、卖家发货的速度三个部分组成，淘宝系统会每天动态计算近6个月数据",
              "2. DSR作为衡量店铺服务水平的最重要指标，在自然搜索中的权重不断得到提升，好的DSR评分可以让店铺排名更靠前，从而带来更多流量，大大提升店铺销量",
              "3. 此工具是根据淘宝网官方提供动态评分计算规则和计算公式而制作的，是卖家分析竞争对手实用程序",
            ],
          },
          {

            title: '店铺动态评分',
            content: [
              "1. 商品发布符合规则、类目属性填写完整、宝贝描述详细、有文字描述和图片说明加细节实拍图、网页点击转化率、网页停留时间和访问深度、买家评分等",
              "2. 服务态度包括：旺旺在线时间和响应速度、转化率、投诉率、退款率、30天内店铺服务质量、回头客占比、买家评分等",
              "3. 发货速度包括：卖家点击发货时间、买家确认收货时间、快递实际发货到达时间、买家评分等",
            ],
          },
          {

            title: '动态评分计算',
            content: [
              "1. 动态评分小于4.4分的，所有店铺宝贝都搜索降权，天猫商城店铺消费者满意评分（“DSR”）平均不低于4.6分",
              "2. 同一客户每月最多只能给出3次动态评分；同一单号买再多商品只能给1次动态评分；给多了不计分",
              "3. 动态评分在双方互评后半小时到24小时显示；一旦评分任何人都无法修改；也不能查看是谁评的分",
              "4. 动态评分在双方互评后半小时显示，有时候也要等24小时；一旦评分任何人都无法修改；也不能查看是谁评的分",
              "5. 淘宝店铺评分计算：必须是交易完成并且是通过支付宝支付的才纳入统计！如果对方退款了打分是不算的",
            ],
          },
          {
            title: '如何提高淘宝店铺动态评分？',
            content: [
              "1. 买家在宝贝差评或者很坏的评价、以及DSR分数很低，会严重影响宝贝的销售， 卖家要及时挽救中差评和负面评价",
              "2. 提高对顾客的服务态度，发货速度，完善商品的描叙。让顾客舒心购物!卖家可以在宝贝评价功能查看评价内容分析来提升客服质量",
              "3. 使用好评返现，可以在宝贝快递包裹里放详细的好评返现方法，顾客收到宝贝后，主动联系顾客是否满意，从而提醒顾客好评并打5分!",
              "4. 对买家进行评估，对于一些经常差评的买家进行拒绝销售，看看这个买家是不是差评师或者喜欢经常给人评价差评的",
              "5. DSR需要特别注意的事情：如果买家不评价，系统默认评价是不计算的，而不是自动给5分。买家不给你评价，就浪费了这单的评价分数，所以一定要客服跟进，让买家尽量给5分评价",
            ],
          },
        ], //介绍相关数组
      };
    },
    components: {
      Introduce,
    },
    methods: {
      queryFn() {
        this.CanYouClick = false;
        this.$store.commit("alterJumpFlag", true);
        this.$store.commit("alterLoadContent", "正在进行计算");
        dsrIntelligentComputing({list: JSON.stringify(this.form)}).then((data) => {
          this.data = data.data;
        }).finally(() => {
          
          this.$store.commit("alterJumpFlag", false);
          this.CanYouClick = true;
        });
      },
      testNumbFn() {
        this.form.forEach((item) => {
          if (String(item.targetCount.length > 3)) {
            item.targetCount = Number(String(item.targetCount).slice(0, 7));
          }
          if (String(item.scoreCount.length > 3)) {
            item.scoreCount = Number(String(item.scoreCount).slice(0, 7));
          }
          item.count =
            Number(String(item.count)) < 0 ? 0 : Number(String(item.count));
          item.targetCount =
            item.targetCount >= 5 ?
            4.99999 :
            item.targetCount < 0 ?
            0 :
            item.targetCount;
          item.scoreCount =
            item.scoreCount >= 5 ?
            4.99999 :
            item.scoreCount < 0 ?
            0 :
            item.scoreCount;
        });
      },
    },
  };
</script>

<style lang="less" scoped>
  .multipleSearch {
    padding: 80px 200px 40px;
    text-align: center;
  }

  .multipleSearchBottom {
    padding: 0 80px 100px;
    text-align: center;
  }

  .calculator {
    margin: 0 auto;
    border: 1px solid #e5e5e5;
    line-height: 68px;
    border-bottom: 0;
    overflow: auto;
    
    .calculatorLeft {
      min-width: 140px;
      height: 68px;
      border-right: 1px solid #e5e5e5;
      border-bottom: 1px solid #e5e5e5;
      background-color: #f8f8f8;
      text-align: center;
      font-size: 12px;
      color: #111111;
    }

    .calculatorRight {
      width: 100%;
      border-bottom: 1px solid #e5e5e5;
    }

    .el-form-item {
      margin-bottom: 0;
      margin-right: 0;
    }

    /deep/.el-input__inner {
      margin: 16px 0;
      height: 36px;
    }

    .formClass {
      text-align: center;
      font-size: 12px;
      color: #999999;
      display: flex;
      border-bottom: 1px solid #e5e5e5;
      width: 100%;
    }
  }

  .querybtn {
    text-align: center;
    margin: 30px 0 0 0;
  }

  .calculator-wrapper{
    display: flex;
    min-width: 1120px;
  }
</style>